import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { updateTenant, fetchAllowedUsers } from '../redux/actions';
import LogoSection from '../components/LogoSection';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase.utils';

const TenantDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tenantId } = useParams();
    const tenants = useSelector((state) => state.tenants);
    const userRole = useSelector((state) => state.userRole);
    const [isEditing, setIsEditing] = useState(false);
    const [targets, setTargets] = useState([]);
    const [allowedUsers, setAllowedUsers] = useState([]);

    const [formData, setFormData] = useState({
        tenantName: '',
        id: '',
        logo: '',
        org_id: '',
        clientAccountId: '',
        cash_url: ''
    });

    useEffect(() => {
        const tenant = tenants.find(t => t.id === tenantId);
        if (tenant) {
            setFormData({
                tenantName: tenant.tenantName || '',
                id: tenant.id || '',
                logo: tenant.logo || '',
                org_id: tenant.org_id || '',
                clientAccountId: tenant.clientAccountId || '',
                cash_url: tenant.cash_url || ''
            });
        }
    }, [tenantId, tenants]);

    useEffect(() => {
        const fetchTargets = async () => {
            try {
                const targetsRef = collection(db, "tenants", tenantId, "fundraisingTargets");
                const querySnapshot = await getDocs(targetsRef);
                const targetsData = [];
                querySnapshot.forEach((doc) => {
                    targetsData.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setTargets(targetsData);
            } catch (error) {
                console.error("Error fetching targets:", error);
            }
        };

        if (tenantId) {
            fetchTargets();
        }
    }, [tenantId]);

    useEffect(() => {
        if (tenantId) {
            dispatch(fetchAllowedUsers(tenantId)).then(users => {
                setAllowedUsers(users);
            });
        }
    }, [tenantId, dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await dispatch(updateTenant(tenantId, formData));
        setIsEditing(false);
    };

    if (userRole !== 'admin') {
        return <div>Access denied. Admin privileges required.</div>;
    }

    const renderField = (label, name, value) => {
        return (
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    {label}
                </label>
                {isEditing ? (
                    <input
                        type="text"
                        name={name}
                        value={value}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                ) : (
                    <div className="w-full px-3 py-2 bg-gray-100 rounded-lg">
                        {value}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <LogoSection />
                <div className="w-full max-w-lg">
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-2xl font-bold">Tenant Details</h1>
                        {!isEditing && (
                            <button
                                onClick={() => setIsEditing(true)}
                                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            >
                                Edit
                            </button>
                        )}
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        {renderField("Tenant Name", "tenantName", formData.tenantName)}
                        {renderField("Tenant ID", "id", formData.id)}
                        {renderField("Logo URL", "logo", formData.logo)}
                        {renderField("Organization ID", "org_id", formData.org_id)}
                        {renderField("Client Account ID", "clientAccountId", formData.clientAccountId)}
                        {renderField("Cash URL", "cash_url", formData.cash_url)}

                        <div className="flex space-x-4">
                            {isEditing && (
                                <>
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                    >
                                        Save Changes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setIsEditing(false)}
                                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                            <button
                                type="button"
                                onClick={() => navigate('/manage-targets')}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                            >
                                Back
                            </button>
                        </div>
                    </form>

                    <div className="mt-8">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold">Fundraising Targets</h2>
                            <Link
                                to={`/tenant/${tenantId}/add-target`}
                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                            >
                                + Add New Target
                            </Link>
                        </div>
                        <div className="space-y-2">
                            {targets.map(target => (
                                <div key={target.id} className="flex justify-between items-center p-4 bg-white rounded-lg shadow">
                                    <span className="font-medium">{target.name}</span>
                                    <Link
                                        to={`/tenant/${tenantId}/target/${target.id}`}
                                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                                    >
                                        Edit Target
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TenantDetails; 