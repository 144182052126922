import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signInWithEmailPassword, signUpWithEmailPassword, resetPassword } from '../redux/actions';

const EmailAuth = () => {
    const dispatch = useDispatch();
    const [isSignUp, setIsSignUp] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setMessage('');

        try {
            if (isForgotPassword) {
                const result = await dispatch(resetPassword(email));
                if (result.success) {
                    setMessage('Password reset email sent! Please check your inbox.');
                } else {
                    setError(result.error);
                }
                return;
            }

            const action = isSignUp ? signUpWithEmailPassword : signInWithEmailPassword;
            const result = await dispatch(action(email, password));

            if (!result.success) {
                setError(result.error);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    if (isForgotPassword) {
        return (
            <form onSubmit={handleSubmit} className="space-y-4">
                <h2 className="text-xl font-semibold text-center mb-4">Reset Password</h2>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                />
                {message && <div className="text-green-600 text-sm">{message}</div>}
                {error && <div className="text-red-500 text-sm">{error}</div>}
                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
                >
                    Send Reset Link
                </button>
                <button
                    type="button"
                    onClick={() => setIsForgotPassword(false)}
                    className="w-full text-blue-500 text-sm hover:underline"
                >
                    Back to Sign In
                </button>
            </form>
        );
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-xl font-semibold text-center mb-4">
                {isSignUp ? 'Create Account' : 'Sign In'}
            </h2>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full px-3 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
            />
            {error && <div className="text-red-500 text-sm">{error}</div>}
            <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
                {isSignUp ? 'Sign Up' : 'Sign In'}
            </button>
            <div className="flex justify-between text-sm">
                <button
                    type="button"
                    onClick={() => setIsSignUp(!isSignUp)}
                    className="text-blue-500 hover:underline"
                >
                    {isSignUp ? 'Already have an account?' : 'Need an account?'}
                </button>
                {!isSignUp && (
                    <button
                        type="button"
                        onClick={() => setIsForgotPassword(true)}
                        className="text-blue-500 hover:underline"
                    >
                        Forgot Password?
                    </button>
                )}
            </div>
        </form>
    );
};

export default EmailAuth; 