// reducers.js

const initialState = {
    value: 0,
    signedInStatus: false,
    userDetails: {},
    trevorURL: "",
    trevorURLAvailable: false,
    userDetailsAvailable: false,
    userDetailsURL: "",
    userTenant: "",
    userEmail: "",
    donorEmail: "",
    userDetailsProvided: false,
    fundraisingTargets: [],
    userSelectedFundraisingTarget: "",
    posResponse: {},
    awaitingPOSResponse: false,
    awaitingPOSStatusResponse: false,
    awaitingReaderResponse: false,
    selectedTargetCode: "",
    readers: [],
    posStatus: {},
    logoURL: localStorage.getItem('logoURL') || "",
    logoLoading: true,
    clientAccountId: "",
    readerStatus: 'offline',
    allowedUsers: [],
    userLogs: [],
    userRole: "",
    tenants: [],
    paymentSuccess: {
        show: false,
        status: null,
        amount: null,
        email: null
    },
    completedPayments: [],
    completedPaymentsLoading: false,
    secretToken: "",
    fileUploadInProgress: false,
    appReady: false,
    cash_url: "",
    awaitingCashResponse: false
};

const counterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'counter/increment':
            return { ...state, value: state.value + 1 };
        case 'counter/decrement':
            return { ...state, value: state.value - 1 };
        case 'trevor/setURL':
            return { ...state, trevorURL: action.payload, trevorURLAvailable: true };
        case 'trevor/setUserURL':
            return { ...state, userDetailsURL: action.payload, userDetailsAvailable: true };
        case 'trevor/setUserDetails':
            return { ...state, userEmail: action.payload.email, userTenant: action.payload.tenant, userDetailsProvided: true };
        case 'trevor/clearUserDetails':
            return { ...state, userDetailsURL: "", userEmail: "", userTenant: "", userDetailsProvided: false, userDetailsAvailable: false };
        case 'user/setClientAccountId':
            return { ...state, clientAccountId: action.payload };
        case 'user/setSelectedTargetCode':
            return { ...state, selectedTargetCode: action.payload };
        case 'user/signIn':
            return { ...state, signedInStatus: true, userDetails: action.payload };
        case 'user/setReaderStatus':
            return { ...state, readerStatus: action.payload };
        case 'user/signOut':
            return { ...state, signedInStatus: false, userDetails: {} };
        case 'user/setReaders':
            return { ...state, readers: action.payload };
        case 'user/setAwaitingReaderResponse':
            return { ...state, awaitingReaderResponse: action.payload };
        case 'user/setTenantID':
            return { ...state, userTenant: action.payload.tenantID, fundraisingTargets: action.payload.fundraisingTargets };
        case 'user/fundraisingTargets':
            return { ...state, fundraisingTargets: action.payload };
        case 'user/setDonorEmail':
            return { ...state, donorEmail: action.payload };
        case 'user/setPOSResponse':
            return { ...state, posResponse: action.payload, awaitingPOSResponse: false };
        case 'user/userSelectedFundraisingTarget':
            return { ...state, userSelectedFundraisingTarget: action.payload };
        case 'user/setAwaitingPOSResponse':
            return { ...state, awaitingPOSResponse: action.payload };
        case 'user/setAwaitingPOSStatusResponse':
            return { ...state, awaitingPOSStatusResponse: action.payload };
        case 'user/setLogoURL':
            localStorage.setItem('logoURL', action.payload);
            return {
                ...state,
                logoURL: action.payload,
                logoLoading: false
            };
        case 'user/setPOSstatus':
            return { ...state, posStatus: action.payload, awaitingPOSStatusResponse: false };
        case 'user/setAllowedUsers':
            return { ...state, allowedUsers: action.payload };
        case 'user/setUserLogs':
            return { ...state, userLogs: action.payload };
        case 'user/setUserRole':
            return { ...state, userRole: action.payload };
        case 'user/setTenants':
            return { ...state, tenants: action.payload };
        case 'payment/setSuccess':
            return {
                ...state,
                paymentSuccess: {
                    show: true,
                    status: action.payload.status,
                    amount: action.payload.amount,
                    email: action.payload.email
                }
            };
        case 'payment/clearSuccess':
            return {
                ...state,
                paymentSuccess: {
                    show: false,
                    status: null,
                    amount: null,
                    email: null
                }
            };
        case 'payments/setCompletedPayments':
            return {
                ...state,
                completedPayments: action.payload
            };
        case 'payments/setLoading':
            return {
                ...state,
                completedPaymentsLoading: action.payload
            };
        case 'user/setSecretToken':
            return { ...state, secretToken: action.payload };
        case 'file/setUploadInProgress':
            return { ...state, fileUploadInProgress: action.payload };
        case 'app/setReady':
            return { ...state, appReady: action.payload };
        case 'user/setCashURL':
            return { ...state, cash_url: action.payload };
        case 'tenant/setAllowedUsers':
            return {
                ...state,
                allowedUsers: action.payload
            };
        case 'payment/setAwaitingCash':
            return { ...state, awaitingCashResponse: action.payload };
        default:
            return state;
    }
};

// If you have other reducers, you can combine them using combineReducers
// import { combineReducers } from 'redux';
// export default combineReducers({ counter: counterReducer, ... });

export default counterReducer;