import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoSection from '../components/LogoSection';
import { startListeningToCompletedPayments } from '../redux/actions';

const CompletedTransactions = () => {
    const dispatch = useDispatch();
    const completedPayments = useSelector((state) => state.completedPayments);
    const isLoading = useSelector((state) => state.completedPaymentsLoading);
    const tenantId = useSelector((state) => state.userTenant);
    const [unsubscribe, setUnsubscribe] = useState(null);

    useEffect(() => {
        if (tenantId) {
            const unsub = dispatch(startListeningToCompletedPayments(tenantId));
            setUnsubscribe(() => unsub);
        }

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [tenantId, dispatch]);

    const formatDate = (timestamp) => {
        if (!timestamp) return 'No date';
        return timestamp instanceof Date ? timestamp.toLocaleString() : timestamp.toDate().toLocaleString();
    };

    if (isLoading) {
        return (
            <div className="min-h-screen flex justify-center">
                <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                    <LogoSection />
                    <div className="w-full text-center">
                        <div className="animate-pulse">Loading transactions...</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <LogoSection />
                <h1 className="text-2xl font-bold mb-4">Completed Transactions</h1>
                <div className="w-full">
                    {completedPayments.map((payment) => (
                        <div key={payment.id} className="border rounded-lg p-4 mb-4 shadow-sm">
                            <div className="flex justify-between items-center">
                                <div>
                                    <p className="text-lg font-semibold">${(payment.amount / 100).toFixed(2)}</p>
                                    <p className="text-sm text-gray-600">{payment.email}</p>
                                    <p className="text-sm text-gray-600">Target: {payment.target}</p>
                                </div>
                                <div className="text-right">
                                    <p className="text-sm text-gray-500">
                                        {formatDate(payment.timestamp)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {completedPayments.length === 0 && (
                        <div className="text-center text-gray-500">
                            No completed transactions found
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompletedTransactions; 