import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signInWithGoogle, listenForAuthChanges } from './redux/actions';
import Home from './pages/Home';
import Settings from './pages/Settings';
import UserLogs from './pages/UserLogs';
import ManageTargets from './pages/ManageTargets';
import CompletedTransactions from './pages/CompletedTransactions';
import POSTerminalSetup from './pages/POSTerminalSetup';
import ProtectedRoute from './components/ProtectedRoute';
import TenantDetails from './pages/TenantDetails';
import FundraisingTarget from './pages/FundraisingTarget';
import AddFundraisingTarget from './pages/AddFundraisingTarget';
import Users from './pages/Users';
import EmailAuth from './components/EmailAuth';
const logoURL = 'https://infaque.com/wp-content/uploads/2024/01/Infaque-Primary-Logo-RGB-Canary-768x231.png'; // Update this to the actual path of your logo

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use navigate hook
  const signedInStatus = useSelector((state) => state.signedInStatus);

  useEffect(() => {
    dispatch(listenForAuthChanges());
  }, [dispatch]);

  const handleSignIn = () => {
    console.log('Handling sign in');
    dispatch(signInWithGoogle(() => navigate('/'))); // Pass navigate function as callback
  };

  return (
    signedInStatus ? (
      <div className="flex h-screen bg-gray-200">
        <div className="flex-grow bg-white">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/settings" element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            } />
            <Route path="/user-logs" element={
              <ProtectedRoute>
                <UserLogs />
              </ProtectedRoute>
            } />
            <Route path="/manage-targets" element={
              <ProtectedRoute>
                <ManageTargets />
              </ProtectedRoute>
            } />
            <Route path="/completed-transactions" element={
              <ProtectedRoute>
                <CompletedTransactions />
              </ProtectedRoute>
            } />
            <Route path="/pos-terminal-setup" element={
              <ProtectedRoute>
                <POSTerminalSetup />
              </ProtectedRoute>
            } />
            <Route path="/tenant/:tenantId" element={
              <ProtectedRoute>
                <TenantDetails />
              </ProtectedRoute>
            } />
            <Route path="/tenant/:tenantId/target/:targetId" element={
              <ProtectedRoute>
                <FundraisingTarget />
              </ProtectedRoute>
            } />
            <Route path="/tenant/:tenantId/add-target" element={
              <ProtectedRoute>
                <AddFundraisingTarget />
              </ProtectedRoute>
            } />
            <Route path="/users" element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            } />
          </Routes>
        </div>
      </div>
    ) : (
      <div className="flex flex-col min-h-screen bg-gray-100">
        <div className="flex justify-center mt-8">
          <img src={logoURL} alt="Infaque POS Logo" className="w-40 mb-8" />
        </div>
        <div className="flex flex-col items-center justify-center flex-grow px-4">
          <h1 className="text-4xl font-bold mb-8">Infaque POS</h1>

          <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6 mb-4">
            <EmailAuth />
          </div>

          <div className="w-full max-w-md flex items-center my-4">
            <div className="flex-1 border-t border-gray-300"></div>
            <span className="px-4 text-gray-500">OR</span>
            <div className="flex-1 border-t border-gray-300"></div>
          </div>

          <button
            onClick={handleSignIn}
            className="flex items-center justify-center gap-2 bg-white hover:bg-gray-50 text-gray-700 font-bold py-3 px-6 rounded-full border border-gray-300 shadow-sm w-full max-w-md"
          >
            <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" className="w-5 h-5" />
            Sign in with Google
          </button>
        </div>
      </div>
    )
  );
}
