import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { addFundraisingTarget } from '../redux/actions';
import LogoSection from '../components/LogoSection';

const AddFundraisingTarget = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tenantId } = useParams();
    const userRole = useSelector((state) => state.userRole);

    const [formData, setFormData] = useState({
        id: '',
        name: '',
        code: '',
        cause: false,
        shortURL: ''
    });

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.id.trim()) {
            alert('ID is required');
            return;
        }

        const success = await dispatch(addFundraisingTarget(tenantId, formData));
        if (success) {
            navigate(`/tenant/${tenantId}`);
        }
    };

    if (userRole !== 'admin') {
        return <div>Access denied. Admin privileges required.</div>;
    }

    const renderField = (label, name, type = "text", required = false) => {
        return (
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    {label} {required && <span className="text-red-500">*</span>}
                </label>
                {type === 'checkbox' ? (
                    <input
                        type="checkbox"
                        name={name}
                        checked={formData[name]}
                        onChange={handleInputChange}
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                ) : (
                    <input
                        type="text"
                        name={name}
                        value={formData[name]}
                        onChange={handleInputChange}
                        required={required}
                        className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                )}
            </div>
        );
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <LogoSection />
                <div className="w-full max-w-lg">
                    <div className="flex justify-between items-center mb-6">
                        <h1 className="text-2xl font-bold">Add New Fundraising Target</h1>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        {renderField("Target ID", "id", "text", true)}
                        {renderField("Name", "name", "text", true)}
                        {renderField("Campaign Code", "code")}
                        {renderField("Is Cause", "cause", "checkbox")}
                        {renderField("Short URL", "shortURL")}

                        <div className="flex space-x-4">
                            <button
                                type="submit"
                                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                            >
                                Add Target
                            </button>
                            <button
                                type="button"
                                onClick={() => navigate(`/tenant/${tenantId}`)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddFundraisingTarget; 