import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAllowedUsers, addUser } from '../redux/actions';
import LogoSection from '../components/LogoSection';

const Users = () => {
    const dispatch = useDispatch();
    const logoURL = useSelector((state) => state.logoURL);
    const allowedUsers = useSelector((state) => state.allowedUsers);
    const tenantID = useSelector((state) => state.userTenant);
    const [showAddForm, setShowAddForm] = useState(false);
    const [newUserEmail, setNewUserEmail] = useState('');

    useEffect(() => {
        dispatch(fetchAllowedUsers(tenantID));
    }, [dispatch, tenantID]);

    const handleAddUser = async () => {
        if (!newUserEmail.trim()) {
            alert('Email is required');
            return;
        }

        const success = await dispatch(addUser(newUserEmail));
        if (success) {
            setNewUserEmail('');
            setShowAddForm(false);
        }
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <LogoSection />

                <div className="w-full">
                    <div className="flex justify-between items-center mb-4">
                        <h1 className="text-3xl text-center">Users</h1>
                        <button
                            onClick={() => setShowAddForm(true)}
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                        >
                            + Add New User
                        </button>
                    </div>

                    {showAddForm && (
                        <div className="mb-4 p-4 bg-gray-50 rounded-lg">
                            <input
                                type="email"
                                value={newUserEmail}
                                onChange={(e) => setNewUserEmail(e.target.value)}
                                placeholder="Enter user email"
                                className="w-full px-3 py-2 border rounded-lg mb-2"
                            />
                            <div className="flex space-x-2">
                                <button
                                    onClick={handleAddUser}
                                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                                >
                                    Add User
                                </button>
                                <button
                                    onClick={() => {
                                        setShowAddForm(false);
                                        setNewUserEmail('');
                                    }}
                                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}

                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b">Email</th>
                                    <th className="py-2 px-4 border-b">Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allowedUsers.filter((user) => user.tenantID === tenantID).map((user) => (
                                    <tr key={user.id} className="hover:bg-gray-50">
                                        <td className="py-2 px-4 border-b text-center">{user.email}</td>
                                        <td className="py-2 px-4 border-b text-center">{user.role}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users; 