import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
    const appReady = useSelector((state) => state.appReady);
    const signedInStatus = useSelector((state) => state.signedInStatus);

    if (!signedInStatus) {
        return <Navigate to="/" />;
    }

    if (!appReady && window.location.pathname !== '/') {
        return <Navigate to="/" />;
    }

    return children;
};

export default ProtectedRoute; 