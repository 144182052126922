import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import LogoSection from '../components/LogoSection';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase.utils';

const FundraisingTarget = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { tenantId, targetId } = useParams();
    const userRole = useSelector((state) => state.userRole);
    const [isEditing, setIsEditing] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        code: '',
        cause: false,
        shortURL: ''
    });

    useEffect(() => {
        const fetchTarget = async () => {
            try {
                const targetRef = doc(db, "tenants", tenantId, "fundraisingTargets", targetId);
                const targetSnap = await getDoc(targetRef);

                if (targetSnap.exists()) {
                    setFormData({
                        name: targetSnap.data().name || '',
                        code: targetSnap.data().code || '',
                        cause: targetSnap.data().cause || false,
                        shortURL: targetSnap.data().shortURL || ''
                    });
                }
            } catch (error) {
                console.error("Error fetching target:", error);
            }
        };

        if (tenantId && targetId) {
            fetchTarget();
        }
    }, [tenantId, targetId]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const targetRef = doc(db, "tenants", tenantId, "fundraisingTargets", targetId);
            await setDoc(targetRef, formData, { merge: true });
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating target:", error);
        }
    };

    if (userRole !== 'admin') {
        return <div>Access denied. Admin privileges required.</div>;
    }

    const renderField = (label, name, type = "text") => {
        return (
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    {label}
                </label>
                {isEditing ? (
                    type === 'checkbox' ? (
                        <input
                            type="checkbox"
                            name={name}
                            checked={formData[name]}
                            onChange={handleInputChange}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                    ) : (
                        <input
                            type="text"
                            name={name}
                            value={formData[name]}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    )
                ) : (
                    <div className="w-full px-3 py-2 bg-gray-100 rounded-lg">
                        {type === 'checkbox' ? (formData[name] ? 'Yes' : 'No') : formData[name]}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <LogoSection />
                <div className="w-full max-w-lg">
                    <div className="flex justify-between items-center mb-6">
                        <div>
                            <h1 className="text-2xl font-bold">Fundraising Target Details</h1>
                            <p className="text-gray-600 mt-1">ID: {targetId}</p>
                        </div>
                        {!isEditing && (
                            <button
                                onClick={() => setIsEditing(true)}
                                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            >
                                Edit
                            </button>
                        )}
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-4">
                        {renderField("Name", "name")}
                        {renderField("Campaign Code", "code")}
                        {renderField("Is Cause", "cause", "checkbox")}
                        {renderField("Short URL", "shortURL")}

                        <div className="flex space-x-4">
                            {isEditing && (
                                <>
                                    <button
                                        type="submit"
                                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                                    >
                                        Save Changes
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setIsEditing(false)}
                                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                            <button
                                type="button"
                                onClick={() => navigate(`/tenant/${tenantId}`)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600"
                            >
                                Back
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default FundraisingTarget; 