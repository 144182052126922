import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../redux/actions';
import { Link } from 'react-router-dom';
import settingsIcon from '../images/settings01.png';
import { addReaderToConnectedAccountIfNeeded, cancelPOSPaymentAction } from '../redux/actions';
import LogoSection from '../components/LogoSection';


const Settings = () => {
    const dispatch = useDispatch();
    const logoURL = useSelector((state) => state.logoURL);
    const [stripeTerminalCode, setStripeTerminalCode] = React.useState('');
    const clientAccountId = useSelector((state) => state.clientAccountId);
    const readers = useSelector((state) => state.readers);
    const awaitingReaderResponse = useSelector((state) => state.awaitingReaderResponse);
    const userName = useSelector((state) => state.userDetails.displayName);
    const userEmail = useSelector((state) => state.userDetails.email);
    const userRole = useSelector((state) => state.userRole);
    const tenantID = useSelector((state) => state.userTenant);

    const handleSignOut = () => {
        dispatch(signOut());
    };

    const handleAddReader = () => {
        dispatch(addReaderToConnectedAccountIfNeeded(stripeTerminalCode, clientAccountId));
    };

    const handleCancelPOS = () => {
        dispatch(cancelPOSPaymentAction());
    };


    return (
        <div className="min-h-screen flex justify-center">
            <div className="w-full max-w-screen-lg flex flex-col items-center space-y-3 px-4 md:px-16">
                <LogoSection />

                <div className="w-full text-center mt-4">
                    <p className="text-lg font-medium text-gray-700">Name: {userName}</p>
                    <p className="text-lg font-medium text-gray-700">Email: {userEmail}</p>
                    <p className="text-lg font-medium text-gray-700">Role: {userRole}</p>
                    <p className="text-lg font-medium text-gray-700">Tenant ID: {tenantID}</p>
                </div>

                {/* Buttons */}
                <div className="flex flex-col items-center space-y-4 mt-8">
                    <Link
                        to="/"
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 text-xl w-full text-center"
                    >
                        Payments Page
                    </Link>
                    <Link
                        to="/completed-transactions"
                        className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 text-xl w-full text-center"
                    >
                        View Completed Transactions
                    </Link>
                    <Link
                        to="/pos-terminal-setup"
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 text-xl w-full text-center"
                    >
                        WisePOS Terminal Setup
                    </Link>
                    <button
                        onClick={handleCancelPOS}
                        className="px-4 py-2 text-white bg-yellow-500 rounded hover:bg-yellow-600 text-xl w-full"
                    >
                        Cancel POS Payment
                    </button>


                    {userRole === 'admin' && (
                        <>
                            <Link
                                to="/user-logs"
                                className="px-4 py-2 text-white bg-purple-500 rounded hover:bg-purple-600 text-xl w-full text-center"
                            >
                                User Logs
                            </Link>
                            <div className="w-full">
                                <label htmlFor="stripeTerminalCode" className="block text-lg font-medium text-gray-700">
                                    Stripe Terminal Code
                                </label>
                                <input
                                    type="text"
                                    id="stripeTerminalCode"
                                    name="stripeTerminalCode"
                                    value={stripeTerminalCode}
                                    onChange={(e) => setStripeTerminalCode(e.target.value)}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <button
                                onClick={handleAddReader}
                                className={`px-4 py-2 text-white rounded text-xl w-full ${awaitingReaderResponse ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
                                disabled={awaitingReaderResponse}
                            >
                                {awaitingReaderResponse ? 'Connecting...' : 'Connect Terminal'}
                            </button>
                            {readers.length > 1 && (
                                <div className="w-full text-center mt-4">
                                    <p className="text-lg font-medium text-gray-700">
                                        Number of connected readers: {readers.length}
                                    </p>
                                </div>
                            )}

                            <Link
                                to="/manage-targets"
                                className="px-4 py-2 text-white bg-purple-500 rounded hover:bg-purple-600 text-xl w-full text-center"
                            >
                                Manage Tenants & Targets
                            </Link>
                        </>
                    )}
                    <button
                        onClick={handleSignOut}
                        className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600 text-xl w-full"
                    >
                        Sign Out
                    </button>
                    <Link
                        to="/users"
                        className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 text-xl w-full text-center"
                    >
                        View Users
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Settings;
