import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearPaymentSuccess } from '../redux/actions';

const PaymentSuccessModal = () => {
    const dispatch = useDispatch();
    const paymentSuccess = useSelector((state) => state.paymentSuccess);

    if (!paymentSuccess.show) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full mx-4">
                <div className="text-center">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                        <svg className="h-6 w-6 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                        Payment Successful!
                    </h3>
                    <div className="mt-2">
                        <p className="text-2xl font-bold text-gray-900">
                            ${paymentSuccess.amount?.toFixed(2)}
                        </p>
                        {paymentSuccess.email && (
                            <p className="text-sm text-gray-500 mt-2">
                                Receipt sent to: {paymentSuccess.email}
                            </p>
                        )}
                    </div>
                    <div className="mt-5">
                        <button
                            onClick={() => dispatch(clearPaymentSuccess())}
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccessModal; 